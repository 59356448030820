import { gsap } from 'gsap';
import imagesLoaded from 'imagesloaded';
import { CustomEase } from 'gsap/CustomEase';

//==============================================================================
//▼アニメーション
//------------------------------------------------------------------------------
function test() {
  if (document.querySelector('.js-top')) {
    imagesLoaded(document.querySelector('.wrapper'), function () {
      const tl = gsap.timeline();

      const opa = function () {
        // スクロール禁止
        function no_scroll() {
          // PCでのスクロール禁止
          document.addEventListener('mousewheel', scroll_control, { passive: false });
          // スマホでのタッチ操作でのスクロール禁止
          document.addEventListener('touchmove', scroll_control, { passive: false });
        }
        // スクロール禁止解除
        function return_scroll() {
          // PCでのスクロール禁止解除
          document.removeEventListener('mousewheel', scroll_control, { passive: false });
          // スマホでのタッチ操作でのスクロール禁止解除
          document.removeEventListener('touchmove', scroll_control, { passive: false });
        }
        // スクロール関連メソッド
        function scroll_control(event) {
          event.preventDefault();
        }

        no_scroll();

        const top = document.querySelector('.js-top');
        const topitem = document.querySelector('.js-top-item');
        const topimage04 = document.querySelector('.js-top-image-04');
        const topimage05 = document.querySelector('.js-top-image-05');
        const topimage06 = document.querySelector('.js-top-image-06');
        const topimage07 = document.querySelector('.js-top-image-07');
        const topimage08 = document.querySelector('.js-top-image-08');
        const topimage09 = document.querySelector('.js-top-image-09');
        const topimage10 = document.querySelector('.js-top-image-10');
        const topimage11 = document.querySelector('.js-top-image-11');
        const topimage12 = document.querySelector('.js-top-image-12');

        const theTime = Date.now();
        const theData = sessionStorage.getItem('visitedTime');
        let timeThrough = 0;
        timeThrough = theTime - theData;

        if (theData === null || timeThrough > 1800000) {
          tl.to(topitem, {
            duration: 0.5,
            opacity: 1,
          });

          tl.to(topimage04, {
            duration: 0.5,
            opacity: 1,
            // delay: 1,
          })
            .to(topimage05, {
              duration: 0.5,
              opacity: 1,
              // delay: 1.5,
            })
            .to(topimage05, {
              duration: 0.5,
              // delay: 2.5,
              opacity: 0,
            });

          tl.to(topimage06, {
            duration: 0.5,
            opacity: 1,
            // delay: 3,
          });

          tl.to(topimage07, {
            duration: 0.1,
            opacity: 1,
            // delay: 3.2,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });
          tl.to(topimage08, {
            duration: 0.1,
            opacity: 1,
            // delay: 3.1,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });
          tl.to(topimage09, {
            duration: 0.1,
            opacity: 1,
            // delay: 3,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });
          tl.to(topimage10, {
            duration: 0.1,
            opacity: 1,
            // delay: 3.3,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });
          tl.to(topimage11, {
            duration: 0.1,
            opacity: 1,
            // delay: 3.4,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });
          tl.to(topimage12, {
            duration: 0.1,
            opacity: 1,
            // delay: 3.5,
            // ease: CustomEase.create('custom', 0, 0, 0, 1),
          });

          tl.to(top, {
            duration: 3,
            delay: 0.5,
            opacity: 0,
            onComplete: function () {
              return_scroll();
            },
          });

          tl.to(topitem, {
            duration: 3,
            delay: 0.5,
            opacity: 0,
          });

          tl.to(top, { duration: 0, display: 'none' });
        } else {
          document.querySelector('.js-top').style.display = 'none';
          return_scroll();
        }
      };

      opa();
    });
  }
}

test();
